<template>
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.56259 23.2501H18.4376C18.5437 23.2501 18.6449 23.2051 18.7159 23.1263C18.7867 23.0476 18.8213 22.9422 18.8107 22.8368L17.3643 8.7986C17.3445 8.60735 17.1835 8.4622 16.9912 8.4622H15.0382V5.788C15.0382 3.0102 12.778 0.75 10 0.75C7.22204 0.75 4.96184 3.0102 4.96184 5.7882V8.4624H3.00888C2.81653 8.4624 2.6556 8.60787 2.63576 8.7988L1.18936 22.8366C1.17889 22.9424 1.21295 23.0477 1.2842 23.1261C1.35545 23.2044 1.45639 23.2502 1.56248 23.2502L1.56259 23.2501ZM5.71199 5.78829C5.71199 3.42349 7.63575 1.50009 10.0002 1.50009C12.3646 1.50009 14.2884 3.42345 14.2884 5.78829V8.46249H5.71219L5.71199 5.78829ZM3.34759 9.21209H4.96235V12.4859H5.71235V9.21209H14.2882V12.4859H15.0382V9.21209H16.6529L18.0221 22.5003H1.97805L3.34759 9.21209Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.7"
    />
  </svg>
</template>
